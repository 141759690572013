import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client'

export function createApolloClient(): ApolloClient<NormalizedCacheObject> {
  // HTTP link
  const link = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT, // Server URL (must be absolute)
    credentials: 'omit',
  })

  // Generate client
  return new ApolloClient({
    link,
    connectToDevTools: process.env.NODE_ENV === 'development',
    cache: new InMemoryCache(),
  })
}
