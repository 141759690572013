import { TypeCourse, TypeEvent } from './contentful'
import { Course, SocialEvent } from './graphql'

export type ICourseExtended = TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string> & {
  fields: TypeCourse<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields'] & Partial<Course>
}

export type IEventExtended = TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string> & {
  fields: TypeEvent<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields'] & Partial<SocialEvent>
}

export enum themes {
  DARK = 'Dark Navigation',
  LIGHT = 'Light Navigation',
  TRANSPARENT_LIGHT = 'Transparent Light Navigation',
  TRANSPARENT_DARK = 'Transparent Dark Navigation',
}

export enum CoursePriceType {
  ONLINE = 'Online',
  ONSHORE = 'Onshore',
  DOMESTIC = 'Domestic',
  INTERNATIONAL = 'International',
}

export enum navBarStyles {
  HAMBURGER = 'hamburger',
  INLINE = 'inline',
  NOSHOW = 'noShow',
}

export enum navBarLogoPosition {
  CENTER = 'center',
  LEFT = 'left',
}

export enum EmbeddedFormTypes {
  HUBSPOT_FORM = 'Hubspot Form in Page',
  HUBSPOT_FORM_MODAL = 'Hubspot button & modal',
}

export enum homePageVersion {
  V1 = '/',
  V2 = 'v2',
  SUBDOMAIN = 'b2b',
}

export enum CoursePageVersion {
  DEFAULT = 'Default',
  V2 = 'v2',
}

export enum Price {
  FREE = 'Free',
  PAID = 'Paid',
}

export enum MediaType {
  VIDEO = 'Video',
  PODCAST = 'Podcast',
}

export enum ArticleType {
  ARTICLE = 'Article',
  CASE_STUDY = 'Case-study',
  BLOG = 'Blog',
}
