import React from 'react'
import {
  TypeFeaturedContentBlockItem,
  TypeFeaturedContentBlockItemFields,
} from '../../types/contentful'

// These are the shapes that are referenced in the src/styles/globals.css file. It provides a way to
// add clip-path shapes to elements by the class name.

const Shapes = () => {
  return (
    <>
      {/* Bubble Shapes Start */}
      <svg width="0" height="0">
        <clipPath id="bubble" clipPathUnits="objectBoundingBox">
          <path
            d="M0.90646 0.25C0.965321 0.179595 1 0.0932793 1 0H0C0 0.0932793 0.0346789 0.179595 0.0935405 0.25C0.0346789 0.320405 0 0.406721 0 0.5C0 0.593279 0.0346789 0.679595 0.0935405 0.75C0.0346789 0.820405 0 0.906721 0 1H0.999989C0.999989 0.906721 0.96531 0.820405 0.906448 0.75C0.96531 0.679595 0.999989 0.593279 0.999989 0.5C0.999989 0.406721 0.96531 0.320405 0.906448 0.25"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="bubble-wide" clipPathUnits="objectBoundingBox">
          <path
            d="M0.970463 0.166667C0.989053 0.119728 1 0.0621781 1 0H0C0 0.0621892 0.0109472 0.119728 0.0295366 0.166667C0.0109472 0.213606 0 0.271155 0 0.333333C0 0.395512 0.0109472 0.453061 0.0295366 0.5C0.0109472 0.546939 0 0.604489 0 0.666667C0 0.728845 0.0109472 0.786395 0.0295366 0.833333C0.0109472 0.880272 0 0.937822 0 1H1C1 0.937811 0.989053 0.880272 0.970463 0.833333C0.989053 0.786395 1 0.728845 1 0.666667C1 0.604489 0.989053 0.546939 0.970463 0.5C0.989053 0.453061 1 0.395512 1 0.333333C1 0.271155 0.989053 0.213606 0.970463 0.166667Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="bubble-tall" clipPathUnits="objectBoundingBox">
          <path
            d="M0.900433 0.126211C0.963089 0.0906684 1 0.0470895 1 0H0C0 0.0470895 0.0369112 0.0906684 0.0995779 0.126211C0.0369112 0.161753 0 0.205332 0 0.252421C0 0.299511 0.0369112 0.343089 0.0995779 0.378632C0.0369112 0.414174 0 0.457753 0 0.504842C0 0.551932 0.0369112 0.59551 0.0995779 0.631053C0.0369112 0.666595 0 0.710174 0 0.757263H0.00398899C0.00398899 0.802547 0.0394889 0.844453 0.0997445 0.878632C0.0394889 0.91281 0.00398899 0.954716 0.00398899 1H1C1 0.954716 0.9645 0.91281 0.904245 0.878632C0.9645 0.844453 1 0.802547 1 0.757263C1 0.711979 0.963089 0.666595 0.900422 0.631053C0.963089 0.595505 1 0.551932 1 0.504842C1 0.457753 0.963089 0.414174 0.900422 0.378632C0.963089 0.343084 1 0.299511 1 0.252421C1 0.205332 0.963089 0.161753 0.900422 0.126211H0.900433Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="bubble-tall-horizontal" clipPathUnits="objectBoundingBox">
          <path
            d="M0.873789 0.900433C0.909332 0.963089 0.95291 1 1 1L1 0C0.952911 -2.05835e-09 0.909332 0.0369112 0.873789 0.0995779C0.838247 0.0369112 0.794668 -8.97533e-09 0.747579 -1.10337e-08C0.700489 -1.3092e-08 0.656911 0.0369112 0.621368 0.0995779C0.585826 0.0369112 0.542247 -2.0009e-08 0.495158 -2.20673e-08C0.448068 -2.41257e-08 0.404489 0.0369112 0.368947 0.0995779C0.333405 0.0369112 0.289826 -3.10427e-08 0.242737 -3.3101e-08L0.242737 0.00398895C0.197453 0.00398895 0.155547 0.0394889 0.121368 0.0997444C0.0871895 0.0394889 0.0452843 0.00398894 4.54604e-08 0.00398894L1.92342e-09 1C0.0452842 1 0.0871895 0.9645 0.121368 0.904245C0.155547 0.9645 0.197453 1 0.242737 1C0.288021 1 0.333405 0.963089 0.368947 0.900422C0.404495 0.963089 0.448068 1 0.495158 1C0.542247 1 0.585826 0.963089 0.621368 0.900422C0.656916 0.963089 0.700489 1 0.747579 1C0.794668 1 0.838247 0.963089 0.873789 0.900422L0.873789 0.900433Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      {/* Bubble Shapes End */}

      {/* Razor Shapes Start */}
      <svg width="0" height="0">
        <clipPath id="razor" clipPathUnits="objectBoundingBox">
          <path d="M0,0.332 L0.103,0 H1 L1,0.668 L0.897,1 H0 L0,0.332"></path>
        </clipPath>
      </svg>
      {/* Razor Shapes End */}

      {/* Bolt Shapes Start */}
      <svg width="0" height="0">
        <clipPath id="bolt" clipPathUnits="objectBoundingBox">
          <path
            d="M0.2372 0L0 0.5H0.2372L0 1H0.7628L1 0.5H0.7628L1 0H0.2372Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="bolt-wide" clipPathUnits="objectBoundingBox">
          <path
            d="M0.925005 0.333707H0.925079L1 0.000444082L0.0749208 0L0 0.333263H0.0749208L0 0.666337H0.0749208L0 0.999567L0.925079 1L1 0.666781H0.925079L1 0.333707H0.925005Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="bolt-tall" clipPathUnits="objectBoundingBox">
          <path
            d="M0.749633 0.249979L1 0L0.250378 6.31579e-05L0 0.250042H0.250378L0 0.500032H0.250378L0 0.750011H1.1122e-05L0 0.750021H0.250378L0 1L0.749633 0.999937L1 0.749958H0.749633L1 0.499968H0.749633L1 0.249989V0.249979H0.749633Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      {/* Bolt Shapes End */}

      {/* Cube Shape Starts */}
      <svg width="0" height="0">
        <clipPath id="cube" clipPathUnits="objectBoundingBox">
          <path d="M0.309289 0L0 0.309289V1H0.690711L1 0.690711V0H0.309289Z" fill="currentColor" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="cube-wide" clipPathUnits="objectBoundingBox">
          <path
            d="M0.000521222 0.331667L0.157626 0H1L0.999479 0.668322L0.842374 1H4.56348e-08L0.000521222 0.331667Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="cube-tall" clipPathUnits="objectBoundingBox">
          <path
            d="M0.331678 0.000521053L0 0.157626V1L0.668333 0.999484L1 0.842374V0L0.331678 0.000521053Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="cube-large" clipPathUnits="objectBoundingBox">
          <path
            d="M0.000548352 0.165177L0.165815 0H1L0.999452 0.834816L0.834185 1H0L0.000548352 0.165177Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="cube-masters" clipPathUnits="objectBoundingBox">
          <path
            d="M0.000548352 0.215177L0.165815 0H1L0.999452 0.794816L0.834185 1H0L0.000548352 0.165177Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>

      {/* Cube Shape Ends */}

      {/* Tab Shape Starts */}
      <svg width="0" height="0">
        <clipPath id="tab" clipPathUnits="objectBoundingBox">
          <path d="M0 0V1H0.690711L1 0.690711V0H0Z" fill="currentColor" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="tab-wide" clipPathUnits="objectBoundingBox">
          <path
            d="M0.000521126 0L1 0L0.999479 0.668322L0.842374 1H-2.58467e-08L0.000521126 0Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="tab-tall" clipPathUnits="objectBoundingBox">
          <path
            d="M0 0.000521053L0 1L0.668333 0.999484L1 0.842374V0L0 0.000521053Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="tab-large" clipPathUnits="objectBoundingBox">
          <path
            d="M0.000550608 0H1L0.99945 0.834161L0.833528 1H0L0.000550608 0Z"
            fill="currentColor"
          />
        </clipPath>
      </svg>
      {/* Tab Shape Ends */}
    </>
  )
}

export default Shapes

export const getShapeClass = (
  size: TypeFeaturedContentBlockItem<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['size'],
  shape:
    | TypeFeaturedContentBlockItem<'WITHOUT_UNRESOLVABLE_LINKS', string>['fields']['shape']
    | 'Cube Masters',
  orientation?: 'horizontal' | 'vertical'
) => {
  switch (shape) {
    case 'Bolt':
      if (size === 'Medium Wide') {
        return 'bolt-wide'
      }
      if (size === 'Medium Tall') {
        return 'bolt-tall'
      }
      return 'bolt'
    case 'Bubble':
      if (orientation === 'horizontal') {
        return 'bubble-tall-horizontal'
      }
      if (size === 'Medium Wide') {
        return 'bubble-wide'
      }
      if (size === 'Medium Tall') {
        return 'bubble-tall'
      }
      return 'bubble'
    case 'Cube':
      if (size === 'Medium Wide') {
        return 'cube-wide'
      }
      if (size === 'Medium Tall') {
        return 'cube-tall'
      }
      if (size === 'Large Square') {
        return 'cube-large'
      }
      return 'cube'
    case 'Tab':
      if (size === 'Medium Wide') {
        return 'tab-wide'
      }
      if (size === 'Medium Tall') {
        return 'tab-tall'
      }
      if (size === 'Large Square') {
        return 'tab-large'
      }
      return 'tab'
    case 'Cube Masters':
      return 'cube-masters'
    default:
      return ''
  }
}
