import safeStringify from 'fast-safe-stringify'

export const removeCircularReferences = (obj: object) => {
  return JSON.parse(
    safeStringify(obj, (key, value) => {
      // prevent issues trying to render `[Circular]` in contentful
      if (value === '[Circular]') {
        return {
          sys: {
            contentType: {
              sys: {},
            },
          },
        }
      }
      return value
    })
  )
}
