import React from 'react'

const ThemeProvider = () => {
  const dpTheme = {
    '--accent-2-50': '#FBFBE9',
    '--accent-2-100': '#F7F8D3',
    '--accent-2-200': '#F4F5C1',
    '--accent-2-300': '#FFF500',
    '--accent-2-700': '#ADAF1D',
    '--accent-2-900': '#56580E',
    '--accent-3-50': '#F3F6F6',
    '--accent-3-100': '#E8EDEC',
    '--accent-3-400': '#74908E',
    '--accent-3-500': '#E9CB0A',
    '--accent-3-600': '#194641',
    '--accent-4-50': '#d2dcf7',
    '--accent-4-100': '#EEF1FC',
    '--as-secondary-ui-50': '#F2F2F2',
    '--as-secondary-ui-400': '#808080',
    '--secondary-ui-500': '#6B7280',
    '--brand-50': '#FFF7F2',
    '--brand-100': '#FFEFE6',
    '--brand-600': '#FF6100',
    '--brand-900': '#BA4700',
    '--primaryFontFamily': 'Inter',
    '--secondaryFontFamily': 'Inter',
    '--bg-color': '#000000',

    // These colors are from the old settings method, not figma:
    ' --primaryColor': '',
    '--secondaryColor': '',
    '--accentColor': '',
    '--authBgColor': '#BCFFF3',
    '--courseBgColor': '#BCFFF3',
    '--eventbBgColor': '#BCFFF3',
    '--primaryFontColor': '#000000',
    '--secondaryFontColor': '#4B5563',
    '--accentFontColor': '',
    '--course-nav-accent': '',
  }

  const themeProps = dpTheme
  const theme = Object.entries(themeProps).reduce(
    (acc, [key, value]) => `${acc}\n${key}: ${value};`,
    ''
  )

  return (
    <>
      <style jsx global>{`
        :root {
          ${theme}//current harcoded props
        }
        html {
          color: ${themeProps['--primaryFontColor']};
          font-family: ${themeProps['--primaryFontFamily']};
        }
      `}</style>
    </>
  )
}

export default ThemeProvider
