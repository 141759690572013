import { createContext, useContext } from 'react'
import { ContentfulClientApi } from 'contentful'

const ContentfulClientContext =
  createContext<ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'>>(null)

export const useContentfulClient = (): ContentfulClientApi<'WITHOUT_UNRESOLVABLE_LINKS'> => {
  return useContext(ContentfulClientContext)
}

export default ContentfulClientContext
