import React, { ComponentType, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import '../styles/globals.css'
import '../styles/hubspot.css'
import '../../public/digitalpassport/fonts/fonts.css'
import Shapes from '../components/Shapes/Shapes'
import { createApolloClient } from '../lib/apolloClient'
import LocationProvider from '../context/Location/LocationProvider'
import ContentfulClientContext from '../context/ContentfulClient/ContentfulClientContext'
import { createContentfulClient } from '../lib/contentful'
import TagManager from 'react-gtm-module'
import ThemeProvider from '../components/ThemeProvider/ThemeProvider'
import DigitalPassportFavIcon from '../components/FavIcon/DigitalPassportFavicon'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'

type MyAppProps = {
  Component: ComponentType
  pageProps: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const MyApp: ComponentType = ({ Component, pageProps }: MyAppProps) => {
  const client = createApolloClient()
  const contentfulClient = createContentfulClient()

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_GTM) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM,
      })
    }
  }, [])
  return (
    <LocationProvider>
      <ApolloProvider client={client}>
        <ContentfulClientContext.Provider value={contentfulClient}>
          <HubspotProvider>
            <ThemeProvider />
            <DigitalPassportFavIcon />
            <Component {...pageProps} />
            <Shapes /> {/* Used to add the clip-path shapes to the project */}
          </HubspotProvider>
        </ContentfulClientContext.Provider>
      </ApolloProvider>
    </LocationProvider>
  )
}

export default MyApp
